var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            height: _vm.get(_vm.data, "property.height"),
            name: _vm.get(_vm.data, "property.name"),
            width: _vm.get(_vm.data, "property.width"),
            adaptive: true,
            clickToClose: _vm.get(_vm.data, "property.clickoff"),
            "focus-trap": _vm.get(_vm.data, "property.focus"),
            scrollable: true,
          },
          on: {
            "before-open": _vm.handle.opening,
            "before-close": _vm.handle.closing,
            opened: _vm.handle.opened,
            closed: _vm.handle.closed,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-container",
              attrs: { id: _vm.get(_vm.data, "property.name") },
            },
            [
              _vm.get(_vm.data, "property.title")
                ? _c("div", { staticClass: "modal-header" }, [
                    _c("div", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.get(_vm.data, "property.title"))),
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "modal-close",
                        on: { click: _vm.handle.close },
                      },
                      [_c("i", { staticClass: "flaticon2-cross text-dark" })]
                    ),
                  ])
                : _c("div", { staticClass: "modal-header" }, [
                    _c("div", { staticClass: "modal-title" }),
                    _c(
                      "a",
                      {
                        staticClass: "modal-close",
                        on: { click: _vm.handle.close },
                      },
                      [_c("i", { staticClass: "flaticon2-cross text-dark" })]
                    ),
                  ]),
              _vm._t("default"),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }