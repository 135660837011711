<template>
    <div>
        <modal
            :height="get(data, 'property.height')"
            :name="get(data, 'property.name')"
            :width="get(data, 'property.width')"
            :adaptive="true"
            :clickToClose="get(data, 'property.clickoff')"
            :focus-trap="get(data, 'property.focus')"
            :scrollable="true"
            @before-open="handle.opening"
            @before-close="handle.closing"
            @opened="handle.opened"
            @closed="handle.closed"
        >
            <div class="modal-container" :id="get(data, 'property.name')">
                <div v-if="get(data, 'property.title')" class="modal-header">
                    <div class="modal-title">{{ get(data, 'property.title') }}</div>
                    <a @click="handle.close" class="modal-close">
                        <i class="flaticon2-cross text-dark"></i>
                    </a>
                </div>
                <div v-else class="modal-header">
                    <div class="modal-title"></div>
                    <a @click="handle.close" class="modal-close">
                        <i class="flaticon2-cross text-dark"></i>
                    </a>
                </div>
                <slot></slot>
            </div>
        </modal>
    </div>
</template>

<script>
import { reactive } from '@vue/composition-api';

//* plugins
import { get } from 'lodash';

export default {
    name: 'module-modal',
    components: {},
    props: {
        name: String,
        title: String,
        clickoff: String,
        height: String,
        width: String,
        shown: String,
        focus: Boolean,
    },
    setup(props, Vue) {
        const data = reactive({
            property: {
                name: get(props, 'name'),
                focus: get(props, 'focus', false),
                title: get(props, 'title'),
                width: get(props, 'width'),
                height: get(props, 'height', 'auto'),
                clickoff: get(props, 'clickoff') ? false : true,
            },
        });

        const handle = {
            opening: (e) => {
                Vue.emit('opening', e);
                if (get(props, 'shown')) Vue.root.$modal.hide(get(props, 'shown'));
            },
            opened: (e) => {
                Vue.emit('opened', e);
            },
            closing: (e) => {
                Vue.emit('closing', e);
                if (get(props, 'shown')) Vue.root.$modal.show(get(props, 'shown'));
            },
            closed: (e) => {
                Vue.emit('closed', e);
            },
            close: () => {
                Vue.root.$modal.hide(get(data, 'property.name'));
                if (get(props, 'shown')) Vue.root.$modal.show(get(props, 'shown'));
            },
        };

        return { data, props, handle, get };
    },
};
</script>

<style lang="scss">
@import '@/assets/sass/base.mixin.scss';
.modal-container {
    .modal-header {
        border: none;
        padding: 18px 18px 3px 23px;
        background: linear-gradient(27deg, #f9f9f9 -85%, white 70%);
        .modal-title {
            font-weight: 400;
            position: relative;
            top: -5px;
        }
        .modal-close {
            position: relative;
            top: -7px !important;
            right: -6px !important;
            font-size: 32px !important;
            background: #e93666 !important;
            padding: 0px 16px 4px 16px !important;
            border-radius: 16px !important;
            margin-bottom: -30px;
            i {
                font-size: 20px !important;
                color: white !important;
            }
        }
    }
    .modal-footer {
        padding: 6px 6px 6px 10px;
        button {
            border-radius: 0.85rem;
            padding: 8px 15px 9px 15px;
        }
    }
}
.vm--container {
    padding: 20px 0px;
    .vm--modal {
        height: auto !important;
        border-radius: 18px;
        @include media-breakpoint-down(sm) {
            width: auto !important;
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}
</style>
